import "@/lib/mqttws31";
import store from "@/store";
var useTLS = window.location.protocol == "https:" ? true : false; //是否走加密 HTTPS，如果走 HTTPS，设置为 true
var reconnectTimeout = 2000;
//  var mq = {
//    host: "mqtt.bilnn.com",
//    port: 8084,
//    AccessKeyId: "paimaiClientId",
//    AccessKeySecret: "6114ead0d40dc878",
//    barrage_topic: "pai_activity_test",
//    clientId: "pai_chatroom_test@@@webserver_1e8f73086ec450a2",
//  };
class MyMqtt {
  constructor() {
    this.option = store.getters.mqtt;
  }
  init() {
    let { clientId, AccessKeyId, AccessKeySecret, host, port } = this.option;
    // console.log(this.option)
    this.clinet = new Paho.MQTT.Client(
      host, //MQTT 域名
      port, //WebSocket 端口，如果使用 HTTPS 加密则配置为443,否则配置80
      clientId //客户端 ClientId
    );
    let _this = this;
    let options = {
      timeout: 3,
      onSuccess: this.connect.bind(_this),
      mqttVersion: 4,
      onFailure: (message) => {
        setTimeout(_this.init, reconnectTimeout);
      }
    };
    options.userName = AccessKeyId;
    options.password = AccessKeySecret;
    options.useSSL = true; //如果使用 HTTPS 加密则配置为 true
    // this.clinet.onConnectionLost = this.onConnectionLost.bind(_this);
    this.clinet.onMessageArrived = this.onMessageArrived.bind(_this);
    this.clinet.connect(options);
  }
  connect() {
    // this.option = store.getters.mqtt;
    const { barrage_topic } = this.option;
    // console.log(`barrage_topic:${barrage_topic}`)
    console.log("连接成功");   
    this.clinet.subscribe(barrage_topic, { qos: 0 });
    console.log("订阅发布");   
  }
  message(){}
  end() {
    console.log("连接关闭");  
    this.clinet.disconnect();
  }
  onConnectionLost() {
    var _this = this;
    setTimeout(_this.init, reconnectTimeout);
  }
  onMessageArrived(message) {
    var topic = message.destinationName;
    var payload = message.payloadString;
    var msg = typeof payload == "string" ? JSON.parse(payload) : payload; //解析内容
    this.message(msg);
  }
}

export default MyMqtt;

//  function mqtt_callback(msg) {
//    console.log(msg);
//  }
